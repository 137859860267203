<template>
  <div class="displayBetween" style="height: 100%; width: 100%" id="LeftOne"></div>
</template>

<script>
import echartMixin from "@/mixins/echartMixin";
import Index from "@/api/index/index";
import { pxToResponsivePx } from "@/utils/pxToResponsivePx";
export default {
  mixins: [echartMixin],
  created() {
    this.setInfo();
  },
  // mounted() {
  //   let myChart = this.$echarts.init(document.getElementById('LeftOne'));
  //   let ResData = [{
  //     name: '火车',
  //     value: 200
  //   }, {
  //     name: '飞机',
  //     value: 10
  //   }, {
  //     name: '客车',
  //     value: 30
  //   }, {
  //     name: '轮渡',
  //     value: 40
  //   }];
  //   this.getEcharts(myChart, ResData)
  // },
  methods: {
    //params 要处理的字符串
    //length 每行显示长度
    getEqualNewlineString(params, length) {
      let text = ''

      // 一行展示length个
      if (params.length > length) {
        text = params.substr(0, length) + '...'
      } else {
        text = params
      }
      return text
    },

    setInfo(period = 3) {
      this.getInfo({
        // getAnomalyAnalysisErr
        APiFn: Index.getAnomalyAnalysisErr,
        data: { period },
        doName: "LeftOne"
      });
    },
    getEcharts(myChart, ResData) {
      console.log(ResData, '!!!!!');
      ResData = ResData.misre.map(v => {
        return {
          name: v.value,
          value: v.num
        }
      })
      console.log(ResData, '???');
      // return
      var color = [
        "#8d7fec",
        "#5085f2",
        "#e75fc3",
        "#f87be2",
        "#f2719a",
        "#fca4bb",
        "#f59a8f",
        "#fdb301",
        "#57e7ec",
        "#cf9ef1",
      ];

      let option = {
        // backgroundColor: "rgba(255,255,255,1)",
        color: color,
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: "rgba(0,0,0,.9)",
          borderColor: "rgba(147, 235, 248, 0)",
          textStyle: {
            color: "#FFF",
            fontSize: pxToResponsivePx(14),
          },
          confine: true,
          formatter: "{b}<br/>{c}<br/>{d}%"
        },
        legend: {
          orient: "vartical",
          type: "scroll",
          x: "left",
          top: "center",
          left: "2%",
          bottom: "0%",
          data: ResData.map((v) => v.name),
          itemWidth: pxToResponsivePx(8),
          itemHeight: pxToResponsivePx(8),
          itemGap: pxToResponsivePx(16),
          formatter: function (params) {
            var val = "";
            if (params.length > 8) {
              val = params.substr(0, 8) + '\n' + params.substr(8);
              return '{white|' + val + '}';
            } else {
              return params
            }
          },

          textStyle: {
            color: "#fff",
            fontSize: pxToResponsivePx(12),
            rich: {
              white: {
                color: "#fff",
                padding: [pxToResponsivePx(4), 0, 0, 0],
                fontSize: pxToResponsivePx(12),
              }
            },
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            radius: ["25%", "55%"],
            center: ["65%", "50%"],
            // avoidLabelOverlap: false,//是否启用防止标签重叠策略
            minAngle: 10,//扇区最小值
            minShowLabelAngle: 11,//小于这个角度（0 ~ 360）的扇区，不显示标签（label 和 labelLine）
            itemStyle: {
              //图形样式
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontSize: pxToResponsivePx(12),
                position: "outer",
                bleedMargin: pxToResponsivePx(8),
                formatter: (params) => {
                  params.name = this.getEqualNewlineString(params.name, 6)
                  return `${params.name}\n (${params.value}) \n${params.percent}%`
                },
                // formatter: "{text|{b}}\n {value|{c}}{per|({d}%)} ",
                // rich: {
                //   text: {
                //     color: "#fff",
                //     fontSize: 14,
                //     align: "center",
                //     verticalAlign: "middle",
                //     padding: 8,
                //   },
                //   value: {
                //     color: "#CAD9F5",
                //     fontSize: 14,
                //     align: "center",
                //     verticalAlign: "middle",
                //   },
                //   per: {
                //     color: "#CAD9F5",
                //     fontSize: 12,
                //     align: "center",
                //     verticalAlign: "middle",
                //   },
                // },
              },
              // labelLine: {
              //   normal: {
              //     show: true,
              //     lineStyle: {
              //       color: "#fff",
              //     },
              //   },
              // },
              // emphasis: {
              //   show: false,
              //   textStyle: {
              //     fontSize: 14,
              //   },
              // },
            },
            data: ResData,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style></style>
