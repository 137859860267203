<template>
  <!-- <div>
  <canvas width="300" height="300"  id="myCanvas"> <p>这里的任何内容都将在支持 canvas 元素的浏览器上被替换</p> 
 </canvas> 
</div> -->
  <div class="world-cloud-3d">
    <div class="world-cloud-canvas-wrapper">
      <canvas id="world-cloud-canvas" width="800" height="574">
      </canvas>
    </div>
    <div id="weightTags"></div>
  </div>
  <!-- <div class="tagcloud" style="height: 100%; width: 100%" id="centerTop" ref="tagcloud"></div> -->
</template>

<script>
import "../../../assets/js/tagcanvas"
// import 'echarts-wordcloud';
import echartMixin from "@/mixins/echartMixin";
import { misreWordCloud } from "@/api/cwdaping/index";
import { pxToResponsivePx } from "@/utils/pxToResponsivePx";
// const TagCloud = require('TagCloud');

export default {
  data() {
    return {
      wordArr: []
    }
  },
  mixins: [echartMixin],
  created() {
    this.setInfo();
  },
  mounted() {

  },
  // mounted() {
  //   let myChart = this.$echarts.init(document.getElementById('centerTop'));
  //   let ResData = [];
  //   this.getEcharts(myChart, ResData)
  // },
  methods: {
    setInfo() {
      this.getInfo({
        APiFn: misreWordCloud,
        // doName: "centerTop",
      });
    },
    getEcharts(ResData) {
      ResData = Object.keys(ResData).map(key => ({ name: key, value: ResData[key] }));
      console.log(ResData);
      const list = ResData.slice(0, 50)
      // .map(v => v.name)
      console.log(list);
      this.wordArr = list
      // 组件装载成果 绘制 tagcanvas
      this.startWorldCloud();
      // 3d词云
      /* const container = '.tagcloud';
      const texts = list
      const options = {
        // useContainerInlineStyles: false,
        radius: 320,
        keep: true,
        // 词云动画速度模式 slow, normal, fast
        maxSpeed: 'normal',
        initSpeed: 'slow',
        useHTML: true,
      };

      let tc = TagCloud(container, texts, options);
      console.log(TagCloud);
      const items = document.getElementsByClassName('tagcloud--item')
      for (const item of items) {
        item.style.color = ['#5764F6', '#2CE5D9', '#F5BA52', '#8D95BA', '#327AC4', '#F07C37'][this.RandomNumBoth(0, 6)]
        item.style.fontSize = ['14px', '18px', '20px', '28px'][this.RandomNumBoth(0, 1)]
      }
      let rootEl = document.querySelector('.tagcloud');
      rootEl.children[0].style.display = 'none'
      console.log();
      rootEl.addEventListener('mouseover', function clickEventHandler() {
        return tc.pause() 
      });
      rootEl.addEventListener('mouseout', function clickEventHandler() {
        return tc.resume() 
      }); */
      // 普通词云
      /* let option = {
        tooltip: {
          show: true,
          borderColor: "rgba(147, 235, 248, 0)",
          borderWidth: 1,
          padding: [10, 15, 10, 15],
          confine: true,
          backgroundColor: 'rgba(0,0,0,.9)',
          textStyle: {
            color: "#FFF",
          },
          extraCssText: 'box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;'
        },

        series: [
          {
            type: 'wordCloud',
            shape: 'circle',
            left: 'center',
            top: 'center',
            width: '95%',
            height: '95%',
            right: null,
            bottom: null,
            sizeRange: [16, 50],
            rotationRange: [-38, 20],
            rotationStep: 19,
            gridSize: 7,
            drawOutOfBound: false,
            layoutAnimation: true,

            // Global text style
            textStyle: {
              fontFamily: 'PingFangSC-Semibold',
              fontWeight: 600,
              color: function (params) {
                let colors = ['#5764F6', '#2CE5D9', '#F5BA52', '#8D95BA', '#327AC4', '#F07C37']
                return colors[parseInt(Math.random() * 6)];
              },
            },
            emphasis: {
              focus: 'none',
            },

            // Data is an array. Each array item must have name and value property.
            data: list,
          },
        ],
      };
      myChart.setOption(option); */
    },
    // RandomNumBoth(Min, Max) {
    //   var Range = Max - Min;
    //   var Rand = Math.random();
    //   var num = Min + Math.round(Rand * Range); //四舍五入
    //   return num;
    // }
    // 启动词云
    startWorldCloud: function (updateFlag) {
      this.createTagListDom();
      let o = {
        dragControl: true,//拖拽
        decel: 1,
        // shape: 'hring',//球形
        maxSpeed: 0.01, // 添加最大的运动速度
        minSpeed: 0.01, // 添加最小的运动速度这样就可以保证一直运动，不会停止
        textHeight: 25,
        outlineMethod: "colour", // tag hover 之后的 轮廓效果
        fadeIn: 800,
        outlineColour: "#fff456aa",
        outlineOffset: 0,
        depth: 0.2,
        minBrightness: 0.2,
        wheelZoom: false,
        reverse: true, // 运动方向与鼠标移动方向相反
        shadowBlur: 2,
        shuffleTags: true,
        shadowOffset: [1, 1],
        stretchX: 1.3, // Stretch or compress the cloud horizontally. 水平拉伸词云
        initial: [0.1, 0.1], // 给词云添加一个初始的运动方向
        textFont: null, // 字体设置为 null 就会继承 每个 tag的a 标签的字体
        textColour: null, // 字体颜色设置为 null 就会继承 每个 tag的a 标签的字体颜色
        weight: true, // weight 打开，就可以根据默认的字体的大小作为权重，对tag 的样式进行调整
        // weightMode: "size", // 样式调整的方式
        // weightSize: 0.5, // 调整 tag 字体的大小，加个 权重
      };
      try {
        // 如果不是更新，说明是第一次渲染，就启动 tagcanvas, 否则就代表更新
        if (!updateFlag) {
          TagCanvas.Start("world-cloud-canvas", "weightTags", o);
        } else {
          TagCanvas.Update("world-cloud-canvas");
        }
      } catch (e) { }
    },
    // 根据父组件传过来的 wordArr 创建 TagList Dom列表，放到页面中供，canvas 渲染
    // 这里后端的数组中的数据结构是一个对象 { name: 要展示的tag名字， light: true/false 据定是否要加重展示}
    createTagListDom: function () {
      let res = [...this.wordArr];
      let fragment = new DocumentFragment();
      for (let i = 0; i < res.length; i++) {
        let a = document.createElement("a");
        // 字符串长度大于10就要换行
        if (res[i].name.length > 10) {
          let charArr = res[i].name.split("");
          charArr.splice(10, 0, "<br>");
          res[i].name = charArr.join("");
        }
        a.innerHTML = res[i].name;
        a.href = "javascript:void(0)";
        let readomValue = Math.ceil(Math.random() * 10);
        let obj = {
          1: 'huge',
          2: 'huge',
          3: 'large',
          5: 'large',
          4: 'medium',
          6: 'small',
          7: 'five',
          8: 'six',
          9: 'five',
          10: 'six',
        }
        a.className = obj[readomValue]
        // 如果是要加重展示就 设置属性为 huge 或large, 否则就设置属性为 medium 或small
        // if (readomValue == 2) {
        //   a.className = "huge" //: "large";
        // } else if(readomValue == 3){
        //   a.className = "medium"// : "small";
        // }else if(readomValue == 4){
        //   a.className = "five" //: "six";
        // }
        fragment.append(a);
      }
      // 更新 tagContainer中的 tag元素
      let tagsContainer = document.querySelector("#weightTags");
      tagsContainer.innerHTML = "";
      tagsContainer.append(fragment);
    },
  },
};
</script>

<style lang="scss">
.world-cloud-3d {
  .world-cloud-canvas-wrapper {
    width: 9.0588rem;
    height: 6.7059rem;
    // max-width: 600px;
    // max-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #weightTags {
    opacity: 0;
    font-size: 0.1412rem;

    .huge {
      font-size: .3294rem;
      color: #3766ff;
    }

    .large {
      font-size: .1647rem;
      color: #2ce5d9;
    }

    .medium {
      font-size: 0.2353rem;
      color: #f5ba52;
    }

    .small {
      font-size: .1882rem;
      color: #8d7fec;
    }

    .five {
      font-size: .2588rem;
      color: #8D95BA;
    }

    .six {
      font-size: .2118rem;
      color: #F07C37;
    }
  }
}
</style>
