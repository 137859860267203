<template>
  <div class="displayBetween" style="height: 100%; width: 100%" id="RightThree"></div>
</template>
  
<script>
import echartMixin from "@/mixins/echartMixin";
import myEchartFn from "@/utils/echarts/index";
import { planMonitorDataCheck } from '@/api/cwdaping/index'
import { pxToResponsivePx } from "@/utils/pxToResponsivePx";
export default {
  mixins: [echartMixin],
  data() {
    return {

    }
  },
  created() {
    this.setInfo()
  },
  // mounted() {
  //   let myChart = this.$echarts.init(document.getElementById('RightThree'));
  //   let ResData = [];
  //   this.getEcharts(myChart, ResData)
  // },
  methods: {
    setInfo() {
      this.getInfo({
        APiFn: planMonitorDataCheck,
        doName: 'RightThree'
      })
    },
    getEcharts(myChart, ResData) {
      ResData = [
        {
          name: '原文已修改',
          value: Number(ResData.updateCount)
        },
        {
          name: '原文未修改',
          value: Number(ResData.notDisposed)
        },
        {
          name: '原文已删除',
          value: Number(ResData.deleteCount)
        },
      ]
      let data = [];
      // let currentIndex = 0;
      let oldIndex;
      let barChartList = ResData
      var color = ['#50F2D3', '#325DFF', '#25BDF6'];
      for (var i = 0; i < barChartList.length; i++) {
        data.push(
          {
            value: barChartList[i].value,
            name: barChartList[i].name,
            zlevel: 100,
            itemStyle: {
              normal: {
                borderWidth: pxToResponsivePx(6),
                borderRadius: pxToResponsivePx(3), // 圆角
              }
            }
          },
          {
            value: 1,
            name: '',
            itemStyle: {
              normal: {
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                color: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 0
              }
            }
          }
        );
      }
      var seriesOption = [
        {
          name: "阴影圈",
          type: "pie",
          radius: pxToResponsivePx(80),
          center: ['40%', '50%'],
          emphasis: {
            scale: false,
          },
          tooltip: {
            show: false,
          },
          itemStyle: {
            color: "rgba(204,225,255, 0.05)",
          },
          labelLine: {
            show: false,
          },

          data:
            [0]
          ,
        },
        {
          type: 'pie',
          center: ['40%', '50%'],
          radius: [pxToResponsivePx(90), pxToResponsivePx(100)],
          legendHoverLink: true,
          label: {
            show: false,
            avoidLabelOverlap: true,
            position: 'none',
            // formatter: '{c_style|{d}%}\n{b_style|{b}}',
            // formatter: '{b_style|{b}}\n{c1_style|占比:}{c_style|{c}}\n{d1_style|数量:}{d_style|{d}%}',
            formatter(params) {
              return ''
            },
            rich: {
              b_style: {
                padding: [0, 0, pxToResponsivePx(10), 0],
                fontSize: pxToResponsivePx(18),
                fontWeight: 400,
                color: '#50F2D3'
              },
              c1_style: {
                padding: [0, 0, pxToResponsivePx(10), 0],
                fontSize: pxToResponsivePx(16),
                fontWeight: '400',
                color: '#fff'
              },
              c_style: {
                padding: [0, 0, pxToResponsivePx(10), 0],
                fontSize: pxToResponsivePx(20),
                fontWeight: 'bold',
                color: '#fff'
              },
              d_style: {
                padding: [0, 0, pxToResponsivePx(10), 0],
                fontSize: pxToResponsivePx(20),
                fontWeight: 'bold',
                color: '#fff'
              },
              d1_style: {
                padding: [0, 0, pxToResponsivePx(10), 0],
                fontSize: pxToResponsivePx(16),
                fontWeight: '400',
                color: '#fff'
              },
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: pxToResponsivePx(14),
              fontWeight: 'normal'
            }
          },
          labelLine: {
            show: false
          },
          data: data
        }
      ];
      let option = {
        color: color,
        legend: {
          type: "scroll",
          show: true, // 是否显示图例
          orient: "vertical", // 图例排列方向
          icon: "circle", // 图例样式为圆形
          itemWidth: pxToResponsivePx(16), // 图例图形的宽度
          // itemHeight: 165, // 图例图形的高度
          itemGap: pxToResponsivePx(10), // 图例项之间的间隔
          right: "13%", // 图例距离容器右侧的距离
          bottom: '13%',
          textStyle: {
            // width:150,
            color: "#C6DAFF", // 图例文字颜色
            fontSize: pxToResponsivePx(14),
            //    overflow:'break'
          },
          // tooltip: {
          //   show: true,
          // },
          // formatter: (params) => myEchartFn.axisLabelFormatter(params, 7),
        },
        title: {
          text: ResData[0].name,
          subtext: `数量 ${ResData[1].value}\n\n占比${(ResData[1].value / (ResData[0].value * 1 + ResData[1].value * 1 + ResData[2].value * 1) * 100).toFixed(2)}%`,
          // text: '1111',
          // subtext: '2222',
          x: "38%",
          y: "38%",
          // itemGap: 15,
          textStyle: {
            color: "#50F2D3",
            fontSize: pxToResponsivePx(18),
            // fontWeight: "bold",
          },
          subtextStyle: {
            color: "#f5f5f6",
            fontSize: pxToResponsivePx(14),
            fontWeight: "bold",
          },
          textAlign: "center",
        },
        series: seriesOption
      };

      myChart.on('mouseover', (params) => {
        console.log(params, 'sdadsa');
        let { name, value, percent } = params
        value == 0 ? console.log(1) : title(name, value, percent)  //处理阴影圆的鼠标划入事件
      })
      function title(name, value, percent) {
        option.title.text = name
        option.title.subtext = `数量 ${value}\n\n占比 ${percent}%`;
        myChart.setOption(option)
      }

      // let currentIndex = -1; // 当前高亮图形在饼图数据中的下标
      // let changePieInterval = setInterval(selectPie, 1000); // 设置自动切换高亮图形的定时器

      // function highlightPie() { // 取消所有高亮并高亮当前图形
      //   for (let idx in option.series[1].data)
      //     // 遍历饼图数据，取消所有图形的高亮效果
      //     myChart.dispatchAction({
      //       type: 'downplay',
      //       seriesIndex: 0,
      //       dataIndex: idx
      //     });
      //   // 高亮当前图形
      //   myChart.dispatchAction({
      //     type: 'highlight',
      //     seriesIndex: 0,
      //     dataIndex: currentIndex
      //   });
      // }

      // myChart.on('mouseover', (params) => { // 用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
      //   if (changePieInterval)
      //     clearInterval(changePieInterval);
      //   currentIndex = params.dataIndex;
      //   highlightPie();
      // });

      // myChart.on('mouseout', (params) => { // 用户鼠标移出时，重新开始自动切换
      //   if (changePieInterval)
      //     clearInterval(changePieInterval);
      //   changePieInterval = setInterval(selectPie, 1000);
      // });

      // function selectPie() { // 高亮效果切换到下一个图形
      //   let dataLen = option.series[1].data.length;
      //   currentIndex = (currentIndex + 1) % dataLen;
      //   highlightPie();
      // }
      myChart.setOption(option);
    }
  }
}
</script>
  
<style>
</style>