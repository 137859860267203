<template>
  <div class="box" style="height: 100%; width: 100%" id="centerTop1">
    <img class="diqiu" src="../../../assets/img/views/cuwu/diqiu.png" alt="">
    <!-- <img class="neiquan" src="../../../assets/img/views/cuwu/neiquan.png" alt=""> -->
    <img class="guangquan" src="../../../assets/img/views/cuwu/guangquan.png" alt="">
    <div class="title">
      <span class="zh">错误表述数据</span>
      <span class="en">Misrepresentation of data</span>
    </div>
    <div class="data-del data-data">
      <div class="name">原文未修改</div>
      <!-- <div class="value">{{notDisposed}}</div> -->
      <countTo class="value" :startVal="startData.notDisposed" :endVal="detailData.notDisposed" separator="," :duration="3000"></countTo>
      
      <div class="dotted"></div>
    </div>
    <div class="data-edit data-data">
      <div class="name">原文已删除</div>
      <!-- <div class="value">{{deleteCount}}</div> -->
      <countTo class="value" :startVal="startData.deleteCount" :endVal="detailData.deleteCount" separator="," :duration="3000"></countTo>

      <div class="dotted"></div>
    </div>
    <div class="data-sta data-data">
      <div class="name">错误表述总量</div>
      <!-- <div class="value">{{planMisreDataCount}}</div> -->
      <countTo class="value" :startVal="startData.planMisreDataCount || 0" :endVal="detailData.planMisreDataCount || 0" separator="," :duration="3000"></countTo>
      
      <div class="dotted"></div>
    </div>
    <div class="data-err data-data">
      <div class="name">原文已修改</div>
      <!-- <div class="value">{{updateCount}}</div> -->
      <countTo class="value" :startVal="startData.updateCount" :endVal="detailData.updateCount" separator="," :duration="3000"></countTo>

      <div class="dotted"></div>
    </div>
  </div>
</template>

<script>
import echartMixin from "@/mixins/echartMixin";
import { planMonitorDataCheck } from '@/api/cwdaping/index'
import {  totalStat } from "@/api/daping/index";
import { detail } from "@/api/platformaccount";
import countTo from "vue-count-to";
import WebSocketMixin from '@/mixins/websocket';
export default {
  mixins: [echartMixin,WebSocketMixin],
  components: {
    countTo
  },
  data() {
    return {
      deleteCount:0,//原文已删除
      notDisposed:0,//原文未修改
      updateCount:0,//原文已修改
      planMisreDataCount: 0,//错误表述总量
      startData: {
        deleteCount:0,//原文已删除
        notDisposed:0,//原文未修改
        updateCount:0,//原文已修改
        planMisreDataCount: 0,//错误表述总量
      },
      detailData: {
        deleteCount:0,//原文已删除
        notDisposed:0,//原文未修改
        updateCount:0,//原文已修改
        planMisreDataCount: 0,//错误表述总量
      },
    }
  },
  watch: {
    receivedMessage(newV) {
      console.log(newV);
      this.startData = this.detailData
      if (newV.deleteCount) this.detailData.deleteCount = newV.deleteCount
      if (newV.notDisposed) this.detailData.notDisposed = newV.notDisposed
      if (newV.updateCount) this.detailData.updateCount = newV.updateCount
      if (newV.planMisreDataCount) this.detailData.planMisreDataCount = newV.planMisreDataCount
    }
  },
  created() {
    this.setInfo()
    this.totalStat()
  },
  methods: {
    setInfo() {
      this.getInfo({
        APiFn: planMonitorDataCheck
      })
    },
    async totalStat() {
      let Res = await totalStat()
      let ResData = Res?.data?.data || {}
      this.detailData.planMisreDataCount = ResData.planMisreDataCount/1
    },
    getEcharts(ResData) {
      this.detailData.deleteCount = ResData.deleteCount/1,
      this.detailData.notDisposed = ResData.notDisposed/1,
      this.detailData.updateCount = ResData.updateCount/1,
      console.log(ResData);
    }
  },

}
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  .title {
    position: absolute;
    top: 2.3529rem;
    left: 3.4118rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: zcoolqingkehuangyouti;
    font-weight: 400;
    color: #ffffff;
    text-shadow: -0.0118rem 0.0235rem 0.0118rem rgba(0, 57, 131, 0.48);
    .zh {
      font-size: 0.3765rem;
    }
    .en {
      font-size: 0.1882rem;
    }
  }
  .data-data {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    .name {
      font-size: 0.1882rem;
    }
    .value {
      font-size: 0.3765rem;
    }
    .dotted {
      position: absolute;
      bottom: -1.1765rem;
      width: 0;
      height: 1.1765rem;
      border-right: 0.0118rem dashed #24ffed;
    }
  }
  .data-del {
    top: 2.2353rem;
    left: 0;
    width: 1.8235rem;
    height: 1.8235rem;
    border: 0.0118rem solid #24ffed;
    border-radius: 50%;
    background-image: radial-gradient(circle, #060d2a, #054765);
    transition: linear .2s;
    // animation: qiqiu1 2s linear infinite;
    &:hover{
      top: 2.1176rem;
      box-shadow: 1px 1px 2px #24ffed;
    }
    .name {
      color: #24ffed;
      margin-top: 0.4471rem;
    }
  }
  .data-edit {
    top: 0.3529rem;
    left: 1.2941rem;
    width: 1.6471rem;
    height: 1.6471rem;
    border: 0.0118rem solid #ffef40;
    border-radius: 50%;
    background-image: radial-gradient(circle, #060d2a, #514d2e);
    transition: linear .2s;
    // animation: qiqiu2 2s linear infinite;
    &:hover{
      top: .2353rem;
      box-shadow: 1px 1px 2px #ffef40;
    }
    .name {
      color: #ffef40;
      margin-top: .4118rem;
    }
    .value {
      font-size: 0.3294rem;
    }
    .dotted{
      bottom: -1.8824rem;
      height: 1.8824rem;
      border-right: 0.0118rem dashed #ffef40;
    }
  }
  .data-sta {
    top: 0.3529rem;
    right: 1.2941rem;
    width: 1.6471rem;
    height: 1.6471rem;
    border: 0.0118rem solid #ffad1d;
    border-radius: 50%;
    background-image: radial-gradient(circle, #060d2a, #493227);
    transition: linear .2s;
    // animation: qiqiu2 2s linear infinite;
    &:hover{
      top: .2353rem;
      box-shadow: 1px 1px 2px #ffad1d;
    }
    .name {
      color: #ffad1d;
      margin-top: .4118rem;
    }
    .value {
      font-size: 0.3294rem;
    }
    .dotted{
      bottom: -1.8824rem;
      height: 1.8824rem;
      border-right: 0.0118rem dashed #ffad1d;
    }
  }
  .data-err {
    top: 2.2353rem;
    right: 0;
    width: 1.8235rem;
    height: 1.8235rem;
    border: 0.0118rem solid #25ff8c;
    border-radius: 50%;
    background-image: radial-gradient(circle, #060d2a, #064743);
    transition: linear .2s;
    &:hover{
      top: 2.1176rem;
      box-shadow: 1px 1px 2px #25ff8c;
    }
    // animation: qiqiu1 2s linear infinite;
    .name {
      color: #25ff8c;
      margin-top: 0.4471rem;
    }
    .dotted{
      border-right: 0.0118rem dashed #25ff8c;
    }
  }
  .diqiu {
    position: absolute;
    top: 1.0588rem;
    left: 2.1765rem;
    width: 5.0471rem;
    height: 4.5412rem;
    // animation: diqiu 2s linear infinite;
  }
  .neiquan {
    position: absolute;
    top: 2.9412rem;
    left: 1.8824rem;

    width: 4.7059rem;
    // height: 1.8rem;
    animation: guangquan 1s linear infinite;
    transform-style: preserve-3d;
    transform: rotateX(85deg);
  }
  .guangquan {
    position: absolute;
    bottom: 0;
    left: -0.5294rem;
    width: 10.5882rem;
    height: 1.8rem;
    /*第一个参数是关键帧的名字，第二个参数是动画的时长，
    第三个参数是动画从头到尾的速度是相同的。
	第四个参数是播放的次数，infinite是无限次	
	*/
    animation: guangquan 2s linear infinite;
  }
}
@keyframes qiqiu1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3529rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes qiqiu2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3529rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes guangquan {
  0% {
    transform: scale(1);
    // transform: rotateX(85deg);
  }
  50% {
    transform: scale(1.05);
    // transform: rotateX(85deg);
  }
  100% {
    transform: scale(1);
    // transform: rotateX(85deg);
  }
}
</style>