<template>
  <div class="online-review">
    <!-- 顶部 + 时间 -->
    <div class="online-top">
      <span>属地平台及内容监管系统</span>
      <img class="top-img" src="@/assets/img/views/shudi/topBG.png" />
      <img class="top-img1" src="@/assets/img/views/shudi/topBG1.png" />
      <div class="online-time"></div>
      <div class="fullScreen">
        <fullScreen></fullScreen>
      </div>
    </div>
    <!-- 左一 -->
    <div class="online-one left-one">
      <div class="top displayBetween">
        <span class="top-p">错误表述类型分布情况</span>
      </div>
      <div class="cont columnBetween">
        <LeftOneVue></LeftOneVue>
      </div>
    </div>
    <!-- 左二 -->
    <div class="online-one left-tow">
      <div class="top displayBetween">
        <span class="top-p">错误词排行榜</span>
        <TabSeclect @setTabFn="setTabFn" :tabIndex="1" :dataList="['近7天', '近30天', '近半年', '近一年']"></TabSeclect>
      </div>
      <div class="cont columnBetween bing">
        <LeftTwoVue ref="leftTwoVue"></LeftTwoVue>
      </div>
    </div>
    <!-- 左三 -->
    <div class="online-one left-three">
      <div class="top displayBetween pe">
        <span class="top-p">错误表述账号排行榜</span>
        <TabSeclect @setTabFn="setTabFn" :tabIndex="2" :dataList="['近7天', '近30天', '近半年', '近一年']"></TabSeclect>
      </div>
      <div class="cont columnBetween bing">
        <LeftThreeVue ref="leftThreeVue"></LeftThreeVue>
      </div>
      <!--  -->
    </div>
    <!-- 右一 -->
    <div class="online-one right-one">
      <div class="top displayBetween pe">
        <span class="top-p">错误表述监测账号数</span>
      </div>
      <div class="cont">
        <RightOneVue ref="RightOneVue"></RightOneVue>
      </div>
    </div>
    <!-- 右二 -->
    <div class="online-one right-tow">
      <div class="top displayBetween">
        <span class="top-p">预警情况</span>
        <TabSeclect @setTabFn="setTabFn" :tabIndex="3" :dataList="['近7天', '近30天', '近半年', '近一年']"></TabSeclect>
      </div>
      <div class="cont">
        <RightTwoVue ref="RightTwoVue" />
      </div>
    </div>
    <!-- 右三 -->
    <div class="online-one right-three">
      <div class="top displayBetween pe">
        <span class="top-p">错误表述轮巡情况</span>
      </div>
      <div class="cont">
        <RightThreeVue />
      </div>
    </div>
    <!-- 中下 -->
    <div class="online-tow zhong-tow">
      <div class="top displayBetween">
        <span class="top-p">发布内容概况</span>
        <img src="" alt="" srcset="" />
        <TabSeclect @setTabFn="setTabFn" :tabIndex="4" :dataList="['近7天', '近30天', '近半年', '近一年']"></TabSeclect>
      </div>
      <div class="cont">
        <CenterBottom ref="CenterBottom" />
      </div>
    </div>
    <!-- 中地图 -->
    <div class="online-three zhong-one">
      <div class="top displayBetween" v-if="isshow==2">
        <span class="top-p">错误表述词频</span>
      </div>
      <div class="cont cont-bg">
        <CenterTopMap v-if="isshow==1"></CenterTopMap>
        <CenterTop v-if="isshow==2"></CenterTop>
      </div>
      <div class="zhong-one-tab">
        <div class="tab-btn cur" :class="{'tab-active':isshow==1}" @click="changeTab(1)">数据</div>
        <div class="tab-btn cur" :class="{'tab-active':isshow==2}" @click="changeTab(2)">词云</div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterTop from './cwCenterTop.vue'
import CenterTopMap from './cwCenterTopMap.vue'
import CenterBottom from './cwCenterBottom.vue'
import LeftOneVue from "./cwLeftOne.vue";
import LeftTwoVue from "./cwLeftTwo.vue";
import LeftThreeVue from "./cwLeftThree.vue";
import RightOneVue from "./cwRightOne.vue";
import RightTwoVue from "./cwRightTwo.vue";
import RightThreeVue from "./cwRightThree.vue";
import TabSeclect from "@/components/tab2.vue";
import fullScreen from "@/components/fullScreen.vue";
import "@/utils/setRem.js";

export default {
  components: {
    CenterTop,
    CenterTopMap,
    CenterBottom,
    LeftOneVue,
    LeftTwoVue,
    LeftThreeVue,
    RightOneVue,
    RightTwoVue,
    RightThreeVue,
    TabSeclect,
    fullScreen,
  },
  data() {
    return {
      // dateType:null
      isshow:1
    };
  },
  methods: {
    setTabFn(dateType, tabIndex) {
      if (tabIndex == 1) this.$refs["leftTwoVue"].setInfo(dateType);
      if (tabIndex == 2) this.$refs["leftThreeVue"].setInfo(dateType);
      if (tabIndex == 3) this.$refs["RightTwoVue"].setInfo(dateType);
      if (tabIndex == 4) this.$refs["CenterBottom"].setInfo(dateType);
    },
    changeTab(val){
      this.isshow = val
    }
  },
  created() {
    console.log(this.$refs.centerTop);
  },
  beforeDestroy() { },
  async mounted() { },
};
</script>

<style scoped>
/* 下面的代码都是我复制过来的  所以有点多 */
.online-review {
  width: 100%;
  height: 100vh;
  background-color: #060c22;
  background-image: url(../../../assets/img/views/shudi/bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
  position: relative;
  background-size: cover;
}

.fullScreen {
  position: absolute;
  right: 0.31rem;
  top: 0.4rem;
}

.online-top {
  width: 100%;
  height: 1rem;
  /* background-image: url(../../assets/img/views/shudi/topBG.png); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  text-align: center;
  line-height: 0.7rem;
  position: relative;
}
.top-img {
  width: 10.4rem;
  height: 1.1rem;
}
.top-img1 {
  position: absolute;
  left: 50%;
  top: 0.2rem;
  transform: translateX(-50%);
  width: 10.4rem;
  height: 1.1rem;
}
.online-time {
  position: absolute;
  left: 0.25rem;
  top: 0.3rem;
}

.online-top span {
  font-size: 0.38rem;
  font-weight: bold;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-family: SourceHanSansCN;
  /* text-shadow: 0px 2px 1px rgba(17,50,116,0.94); */
  background: linear-gradient(0deg, #a9e2ff 0%, #fafeff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.top {
  width: 100%;
  height: .3294rem;
  /* padding-top: -0.08rem;
    padding-bottom: -0.08rem; */
  /* height: 0.24rem; */
  background-image: url(../../../assets/img/views/shudi/titleBG.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left center;
  position: relative;
}
.topC {
  width: 100%;
  height: 0.24rem;
  background-image: url(../../../assets/img/views/shudi/titleBG.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left center;
  position: relative;
}
.top-p {
  font-family: "MyTitle";
  font-size: 0.19rem;
  font-style: italic;
  color: #ffffff;
  font-weight: 600;
  line-height: .3294rem;
  text-shadow: -1px 2px 1px rgba(0, 57, 131, 0.48);
  /* position: absolute; */
  padding-left: 0.15rem;

  /* left: 0.45rem;
        top: -0.08rem; */
  /* background: linear-gradient(0deg, #5fd4ff 0%, #fafeff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.top p {
  font-family: "MyTitle";
  font-size: 0.19rem;
  font-style: italic;
  color: #ffffff;
  text-shadow: -1px 2px 1px rgba(0, 57, 131, 0.48);
  position: absolute;
  left: 0.45rem;
  top: -0.08rem;
}
.title-tab {
  position: absolute;
  top: 0.1rem;
  left: 6.2rem;
}

.cont {
  width: 100%;
  height: calc(100% - 0.24rem);
  position: relative;
  background: RGBA(10, 31, 81, 0.3);
}
.cont-bg{
  background: none !important;
}

.online-one {
  width: 5.79rem;
  height: 3.62rem;
}

.online-tow {
  width: 9.6rem;
  /* height: 2.44rem; */
  height: 3.6588rem;
  /* background: linear-gradient(
      0deg,
      rgba(46, 111, 157, 0.12) 0%,
      rgba(46, 111, 157, 0) 100%
    ); */
}

.online-three {
  width: 9.4118rem;
  height: 7rem;
}

.zhong-one {
  position: absolute;
  left: 6.5882rem;
  top: 0;
  /* top: -0.2rem; */
  position: relative;
}
.zhong-one-tab{
  position: absolute;
  bottom: -5px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: .14rem;
  color: #fff;
}
.tab-btn{
  width: .5294rem;
  height: .2rem;
  text-align: center;
  margin-right: .1412rem;
  background-image: url(~@/assets/img/views/online/right.png);
}
.tab-active{
  background-image: url(~@/assets/img/views/online/right-active.png);
}
.C-T {
  position: absolute;
  /* top: 15%; */
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: space-between;
}
.C-T-item {
  width: 2rem;
  height: 1.8rem;
  /* background-image: url("../../assets/img/views/shudi/zuo.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: bottom;
  color: #fff;
  text-align: center;
  position: relative;
}
.C-T-item .img1 {
  position: absolute;
  top: 0.2rem;
  width: 2rem;
  height: 100%;
  z-index: 2;
  transform: rotateX(57deg);
}
.C-T-item .img2 {
  position: absolute;
  top: 0.5rem;
  left: -0.2rem;
  width: 2.4rem;
  z-index: 1;
}
.animate-icon {
  position: absolute;
  top: -60px;
  width: 200px;
  height: 200px;
  /* background: #2A2A2A; */
}

/* 压缩div高度，将正圆变为椭圆 */
.flat {
  transform: scaleY(0.375);
}

.circle-outer {
  position: relative;
  left: 20px;
  top: 70px;
}

.circle-outer .content {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  /* 圆锥渐变 */
  /* background: conic-gradient(fuchsia, pink, orange, gold, snow); */
  background: rgb(116, 200, 236);
  /* 将圆中心“掏空”变成圆环 */
  --mask: radial-gradient(closest-side, transparent 94%, black 94%);
  -webkit-mask-image: var(--mask);
  mask-image: var(--mask);
  animation: spin 4s linear infinite;
}
.circle-ball {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: snow;
  animation: animX 2s cubic-bezier(0.36, 0, 0.64, 1) -1s infinite alternate,
    animY 2s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate,
    spin-ball 4s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate;
}
.pie0 {
  top: -188px;
}

/* 设置圆盘逆时针旋转，不透明度动态变化
    （圆盘们浮动在下方叠起时变得更透明，否则变不透明） */

/* 顺时针旋转动画 */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 逆时针旋转动画 */
@keyframes spin-r {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* 圆点横向运动变化，需根据真实效果调整参数 */
@keyframes animX {
  0% {
    left: 24px;
  }
  100% {
    left: 178px;
  }
}

/* 圆点纵向运动变化，需根据真实效果调整参数 */
@keyframes animY {
  0% {
    top: 122px;
  }
  100% {
    top: 182px;
  }
}

/* 圆点尺寸运动变化，实现转动时近大远小的效果 */
@keyframes spin-ball {
  0% {
    transform: scale(0.64);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.64);
  }
}

/* 圆盘不透明度变化效果 */
@keyframes pie-opa {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}

/* 圆盘浮动效果：由于圆盘大小相同、层叠的距离也相同，
    非最底层的圆盘样式可以动态生成以减少重复代码 */
@keyframes pie-ani1 {
  0% {
    top: -198px;
  }
  30% {
    top: -240px;
  }
  70% {
    top: -240px;
  }
  100% {
    top: -198px;
  }
}

@keyframes pie-ani2 {
  0% {
    top: -208px;
  }
  30% {
    top: -293px;
  }
  70% {
    top: -293px;
  }
  100% {
    top: -208px;
  }
}

@keyframes pie-ani3 {
  0% {
    top: -218px;
  }
  30% {
    top: -345px;
  }
  70% {
    top: -345px;
  }
  100% {
    top: -218px;
  }
}

@keyframes pie-ani4 {
  0% {
    top: -228px;
  }
  30% {
    top: -397px;
  }
  70% {
    top: -397px;
  }
  100% {
    top: -228px;
  }
}

.C-T-item img {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 1;
}
.C-T-item .img {
  /* position: absolute;
      left:0;
      animation: icon3 1.6s linear infinite; */
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 10px;
  background: #40dce7;
  /* animation: icon3 1.6s linear infinite; */
  animation: moveX 4s cubic-bezier(0.36, 0, 0.64, 1) -2s infinite alternate,
    moveY 4s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate;
}
@keyframes moveX {
  0% {
    /* left: -22px; */
    transform: translateX(0);
  }
  100% {
    /* left: 282px; */
    transform: translateX(282px);
  }
}
@keyframes moveY {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(282px);
  }
}

@keyframes icon1 {
  0% {
    transform: rotateY(0);
  }

  50% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}
.C-T-item2 {
  width: 2rem;
  height: 1.8rem;
  /* background-image: url("../../assets/img/views/shudi/zuo2.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: bottom;
  color: #fff;
  text-align: center;
  position: relative;
}
.C-C {
  position: absolute;
  bottom: 35%;
  width: 100%;
  /* height: 1rem; */
  display: flex;
  justify-content: center;
}
@keyframes icon1 {
  0% {
    transform: rotateY(0);
  }

  50% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}
.C-C-item {
  position: relative;
  width: 5rem;
  height: 2.8rem;
  /* background-image: url('../../assets/img/views/shudi/BGicon.png'); */
  /* background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position-y: bottom; */
  color: #fff;
  text-align: center;
  padding: 0.3rem 0 0 0;
  /* animation: icon2 1.6s linear infinite; */
}
.C-C-item img {
  position: absolute;
  top: 0.18rem;
  left: 0;
  width: 100%;
  height: 100%;
  animation: icon2 1.6s linear infinite;
}
.C-C-item-num {
  position: absolute;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 0.79rem;
  font-family: "MyMunBold";
}
.C-C-item-desc {
  font-size: 0.24rem;
  text-shadow: 15px 10px 12px rgb(24, 114, 171);
}
.C-C-item-num-span {
  font-size: 0.24rem;
}
.C-B {
  position: absolute;
  bottom: 35%;
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: space-between;
}
.XB {
  /* transform: translateY(1rem); */
  margin-top: 1rem;
}

.C-B-item {
  position: relative;
  width: 3rem;
  height: 1.8rem;
  /* background-image: url('../../assets/img/views/shudi/icon.png'); */
  /* background-repeat: no-repeat; */
  /* background-size: 100% 100%; */
  background-position-y: bottom;
  color: #fff;
  text-align: center;
}
.C-B-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: icon2 1.6s linear infinite;
}
.C-B-item-num {
  position: absolute;
  top: 0.2rem;
  /* left: 50%;  */
  width: 100%;
  font-size: 0.42rem;
  text-shadow: 15px 10px 12px rgb(24, 114, 171);
  z-index: 8;
  font-family: MyMunBold;
}
.C-B-item-num2 {
  position: absolute;
  top: 0.2rem;
  /* left: 50%;  */
  width: 100%;
  font-size: 0.42rem;
  text-shadow: 15px 10px 12px rgba(255, 155, 47, 0.7);
  z-index: 8;
  font-family: MyMunBold;
}
.C-B-item-desc {
  /* width: auto; */
  position: absolute;
  top: 0.9rem;
  left: 50%;
  width: 2rem;
  margin-left: -1rem;
  /* left:'center'; */
  font-size: 0.16rem;
  z-index: 3;
}
.zhong-tow {
  position: absolute;
  left: 6.4rem;
  /* top: 8.1rem; */
  top: 8.73rem;
}

.left-one {
  position: absolute;
  left: 0.24rem;
  top: 1rem;
}

.left-tow {
  position: absolute;
  left: 0.24rem;
  top: 4.87rem;
}

.left-three {
  position: absolute;
  left: 0.24rem;
  top: 8.73rem;
}

.right-one {
  position: absolute;
  right: 0.24rem;
  top: 1rem;
}

.right-tow {
  position: absolute;
  right: 0.24rem;
  top: 4.87rem;
}

.right-three {
  position: absolute;
  right: 0.24rem;
  top: 8.73rem;
}

.time-tag {
  position: absolute;
  right: 0.14rem;
  top: 0.2rem;
  display: flex;
  align-items: center;
  z-index: 1;
}

.online-three .time-tag {
  right: 0;
  left: 0.14rem;
  top: 0.89rem;
}

.online-three .tag {
  width: 0.65rem;
  height: 0.26rem;
}

.tag {
  width: 0.54rem;
  height: 0.21rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  font-size: 0.12rem;
  color: #ffffff;
  text-align: center;
  line-height: 0.21rem;
  cursor: pointer;
}

.tag-left {
  /* background-image: url(../../assets/img/views/online/left.png); */
}

.tag-left-active {
  /* background-image: url(../../assets/img/views/online/left-active.png); */
}

.tag-zhong {
  /* background-image: url(../../assets/img/views/online/zhong.png); */
}

.tag-zhong-active {
  /* background-image: url(../../assets/img/views/online/zhong-active.png); */
}

.tag-right {
  /* background-image: url(../../assets/img/views/online/right.png); */
}

.tag-right-active {
  /* background-image: url(../../assets/img/views/online/right-active.png); */
}

.online-one-sum {
  width: 2.8rem;
  height: 0.73rem;
  background: linear-gradient(
    -86deg,
    rgba(13, 142, 233, 0) 0%,
    rgba(13, 142, 233, 0.3) 100%
  );
  position: absolute;
  left: 0.9rem;
  top: 0.5rem;
  padding-left: 0.6rem;
}

.online-one-sum .p1 {
  font-size: 0.14rem;
  color: #ddefff;
  margin-top: 0.08rem;
}

.online-one-sum .p2 {
  font-family: "MyMunBold";
  font-size: 0.32rem;
  font-weight: bold;
  color: #fff;
}

.online-one-sum .img {
  position: absolute;
  left: -0.5rem;
  top: -0.17rem;
  width: 0.76rem;
  height: 0.93rem;
}

.online-pie {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 1.2rem;
  position: absolute;
  top: 1.4rem;
}

.online-pie-tag {
  width: 26%;
  height: 0.42rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.online-pie-tag-mun {
  font-family: "MyMun";
  font-size: 0.14rem;
  color: #d1e1ff;
  width: 0.5rem;
}

#tag1,
#tag2,
#tag3,
#tag4,
#tag5,
#tag6 {
  width: 0.45rem;
  height: 0.45rem;
}

@keyframes icon2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes quan {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

#echarts2 {
  width: 100%;
  height: 100%;
}
#echarts5 {
  width: 100%;
  height: 100%;
  margin-top: 0.08rem;
}
.wp-total {
  min-width: 1.44rem;
  height: 1.67rem;
  position: absolute;
  left: 0.2rem;
  top: 0.65rem;
  text-align: center;
  padding: 0.36rem 0.2rem 0;
  /* background-image: url(../../assets/img/views/online/kuang.png); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  animation: kuang 1s linear;
  overflow: hidden;
}

@keyframes kuang {
  from {
    left: 1.33rem;
    width: 0;
  }

  to {
    left: 0.51rem;
    width: 1.44rem;
  }
}

.wp-total .p1 {
  font-family: "MyMunBold";
  font-size: 0.56rem;
  color: #ffffff;
  font-weight: bold;
  white-space: nowrap;
  animation: kuangp 1s linear forwards 1s;
  opacity: 0;
}

.wp-total .p2 {
  font-size: 0.16rem;
  color: #f4faff;
  font-weight: bold;
  white-space: nowrap;
  animation: kuangp 1s linear forwards 1s;
  opacity: 0;
}

@keyframes kuangp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.wp-total .guang1 {
  position: absolute;
  left: -0.05rem;
  top: -0.2rem;
  animation: guang 2s linear infinite alternate;
}

.wp-total .guang2 {
  position: absolute;
  right: -0.08rem;
  top: -0.2rem;
  animation: guang 2s linear infinite alternate;
}

@keyframes guang {
  from {
    top: -0.2rem;
  }

  to {
    top: 0.5rem;
  }
}

.wp-category {
  width: 1.92rem;
  height: 2.15rem;
  position: absolute;
  right: 0.4rem;
  top: 0.37rem;
}

.wp-category-tag {
  display: flex;
  align-items: center;
}

.wp-category-tag + .wp-category-tag {
  margin-top: 5px;
}

.wp-category-text {
  width: 2rem;
  height: 0.31rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-image: url(../../assets/img/views/online/biaoqian.png); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  padding: 0 0 0 0.15rem;
}

.wp-category-text .span1 {
  font-size: 0.16rem;
  color: #c5e2fb;
}

.wp-category-text .span2 {
  font-size: 0.24rem;
  font-family: "MyMunBold";
  font-weight: bold;
  color: #ffffff;
}

.good-people {
  overflow: hidden;
  /* padding-bottom:1rem ; */
  /* margin-top: 0.1rem; */
}

.good-people-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0.2rem;
  padding: 0 0.24rem;
  margin-top: 0.23rem;
}

.good-people-tag .span1 {
  width: 0.18rem;
  height: 0.16rem;
  border-radius: 0.03rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.14rem;
  font-family: "MyMun";
  color: #fff;
  border: 1px solid #007aaf;
}

.good-people-tag .span1.one {
  border: none;
  background: #e72f31;
}

.good-people-tag .span1.tow {
  border: none;
  background: #e49b09;
}

.good-people-tag .span1.three {
  border: none;
  background: #008eb7;
}

.good-people-tag .span1.one {
  border: none;
  background: #e72f31;
}

.good-people-tag .span2 {
  display: inline-block;
  width: 1rem;
  font-size: 0.14rem;
  color: #c6daff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.good-people-tag .span3 {
  display: inline-block;
  width: 0.8rem;
  font-size: 0.14rem;
  color: #c6daff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.good-people-tag .span4 {
  display: inline-block;
  width: 0.4rem;
  font-size: 0.14rem;
  color: #c6daff;
}

.good-people-tag .span5 {
  width: 1.8rem;
  height: 0.08rem;
  background: rgba(26, 53, 107, 0.6);
  /* border-radius: 0.04rem; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.good-people-tag .span5 .span-Percentage {
  height: 0.08rem;
  border-radius: 0.04rem;
  background: linear-gradient(90deg, #0562ea 0%, #5fa7fb 100%);
}

.good-people-tag .span5 .span-Percentage.one {
  background: linear-gradient(90deg, #d81315 0%, #fc5f61 100%);
}

.good-people-tag .span5 .span-Percentage.tow {
  background: linear-gradient(90deg, #e68c0b 0%, #f1b94a 100%);
}

.good-people-tag .span5 .span-Percentage.three {
  background: linear-gradient(90deg, #0b7efe 0%, #40dce7 100%);
}

.good-people-tag .span6 {
  display: inline-block;
  width: 0.3rem;
  font-size: 0.14rem;
  color: #c6daff;
}
.good-people-tag2 {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  height: 0.2rem;
  padding: 0 0.24rem;
  margin-bottom: 0.36rem;
}
.good-people-tag2 .span1 {
  width: 0.18rem;
  height: 0.16rem;
  border-radius: 0.03rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.14rem;
  font-family: "MyMun";
  color: #fff;
  border: 1px solid #007aaf;
}

.good-people-tag2 .span1.one {
  border: none;
  background: #e72f31;
}

.good-people-tag2 .span1.tow {
  border: none;
  background: #e49b09;
}

.good-people-tag2 .span1.three {
  border: none;
  background: #008eb7;
}

.good-people-tag2 .span1.one {
  border: none;
  background: #e72f31;
}

.good-people-tag2 .span2 {
  display: inline-block;
  width: 1rem;
  font-size: 0.14rem;
  color: #c6daff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.good-people-tag2 .span20 {
  display: inline-block;
  max-width: 80%;
  font-size: 0.14rem;
  color: #c6daff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.good-people-tag2 .span3 {
  display: inline-block;
  width: 0.8rem;
  font-size: 0.14rem;
  color: #c6daff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.good-people-tag2 .span4 {
  display: inline-block;
  width: 0.4rem;
  font-size: 0.14rem;
  color: #c6daff;
}

.good-people-tag2 .span5 {
  width: 100%;
  height: 0.08rem;
  background: rgba(26, 53, 107, 0.6);
  /* border-radius: 0.04rem; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.good-people-tag2 .span5 .span-Percentage {
  height: 0.08rem;
  border-radius: 0.04rem;
  background: linear-gradient(90deg, #0562ea 0%, #5fa7fb 100%);
}

.good-people-tag2 .span5 .span-Percentage.one {
  background: linear-gradient(90deg, #d81315 0%, #fc5f61 100%);
}

.good-people-tag2 .span5 .span-Percentage.tow {
  background: linear-gradient(90deg, #e68c0b 0%, #f1b94a 100%);
}

.good-people-tag2 .span5 .span-Percentage.three {
  background: linear-gradient(90deg, #0b7efe 0%, #40dce7 100%);
}

.good-people-tag2 .span6 {
  position: absolute;
  right: 0;
  display: inline-block;
  width: auto;
  font-size: 0.14rem;
  color: #c6daff;
}
#map {
  width: 100%;
  height: 6.6rem;
  position: absolute;
  top: 0;
  left: 0;
}

.zhong-img {
  position: absolute;
  bottom: -0.75rem;
  left: -0.6rem;
}
/* 榜数统计*/
.countStat {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 0.5rem;
  background: rgba(127, 184, 255, 0.08);
  margin-bottom: 0.1rem;
  font-size: 0.17rem;
  color: #ffffff;
  padding: 0 0.25rem 0 0.25rem;
  font-weight: bold;
}
.countStat_desc {
  color: #6086c8;
  font-size: 0.08rem;
}
.countStat_com {
  width: 1.5rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  background: #ff4eaa;
  position: relative;
  font-size: 0.1rem;
  font-family: DINPro;
  font-weight: bold;
  color: #0c1126;
}
.countStat_com_span {
  position: absolute;
  right: 0.1rem;
}
.countStat_com_desc {
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 100%;
  /* background: #357ffc; */
  background: linear-gradient(-45deg, transparent 20px, #357ffc 0);
  border-radius: 0.1rem;
  /* transition: all 0.5s ease; */
  padding-left: 0.1rem;
  color: rgba(44, 208, 255, 0.6);
  color: rgba(44, 208, 255, 0.5);
}

.el-carousel__item--card {
  width: auto !important;
}
.el-carousel__item {
  width: auto !important;
}
.el-carousel__item--card.is-active {
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px) !important;
}
.cuowuBox {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 1.3rem; */
  /* height: 0.2rem; */
  background: #002e74;
  border-radius: 10px;
  padding: 0.07rem 0.15rem;
  font-size: 12px;
  color: #9cc3ff;
  cursor: pointer;
  /* margin: 0.06rem 0; */
  margin-top: 0.08rem;
  margin-right: 0.18rem;
}
.cuowuBoxSelect {
  background: #004fc8;
  color: #ffffff;
}
.bing {
  position: relative;
}
.bing_img {
  width: 2.5rem;
  position: absolute;
  left: 2.15rem;
  top: 0.9rem;

  /* z-index:-9 */
}
.pe {
  position: relative;
}
</style>
