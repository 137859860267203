<template>
  <div class="displayBetween" style="height: 100%; width: 100%" id="LeftTwo">
    <div class="progress-z">
      <vue-seamless-scroll :data="list" :class-option="$main.classOption()">
        <div class="progress-item" v-for="(item, index) in list" :key="index">
          <div class="progress-item-bar" :class="getBarColor(index + 1)"
            :style="{ 'width': getBarWidth(item.value, item.maxNum) }">
            <div class="progress-item-name">
              <span>No{{ index + 1 }}.</span>
              <!-- <el-tooltip popper-class="btooltip" effect="dark" :content="item.name" :disabled='item.name.length<7' placement="right-start"> -->
              <span class="name TextHieedn" :title="item.name">{{ item.name }}</span>
              <!-- </el-tooltip> -->

            </div>
            <div class="progress-item-value">{{ item.value }}</div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import echartMixin from "@/mixins/echartMixin";
import { misreWordLeaderboard } from "@/api/cwdaping/index";

export default {
  mixins: [echartMixin],
  data() {
    return {
      list: [
      ]
    }
  },
  created() {
    this.setInfo();
  },
  // mounted() {
  //   let ResData = []
  //   this.getEcharts(ResData)
  // },
  methods: {
    getBarWidth(num, maxNum) {
      let barWidth = (num / maxNum).toFixed(2)
      return (Number(barWidth) + Number(0.4)) * 100 > 100 ? '100%' : (Number(barWidth) + Number(0.4)) * 100 + '%'
    },
    getBarColor(val) {
      let obj = {
        1: 'background-lv1',
        2: 'background-lv2',
        3: 'background-lv3',
      }
      return obj[val]
    },
    setInfo(period = 1) {
      this.getInfo({
        APiFn: misreWordLeaderboard,
        data: { period },
      });
    },
    getEcharts(ResData) {
      this.list = ResData.map(item => {
        const name = Object.keys(item)[0]; // 获取键名
        const value = item[name]; // 获取键值
        return { name, value }; // 返回包含 value 和 key 属性的对象
      });
      let sum = 0
      this.list.forEach(v => sum += Number(v.value))
      console.log(sum);
      this.list.forEach(v => v.maxNum = sum)
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-z {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0.3529rem 0.2471rem;
  overflow: hidden;

  .progress-item {
    width: 5.2941rem;
    height: 0.3059rem;
    margin-bottom: 0.2824rem;
    border-radius: 0.1529rem;
    background: RGBA(29, 45, 88, 0.7);

    .background-lv1 {
      background: linear-gradient(-90deg, #c86a27 0%, #8c3e07 100%) !important;
    }

    .background-lv2 {
      background: linear-gradient(-90deg, #ab8a46 0%, #815d14 100%) !important;
    }

    .background-lv3 {
      background: linear-gradient(-90deg, #20b090 0%, #00788e 100%) !important;
    }

    .progress-item-bar {
      position: relative;
      height: 0.3059rem;
      border-radius: 0.1529rem;
      background: linear-gradient(-90deg, #215db6 0%, #043c9f 100%);

      .progress-item-name {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: .1412rem;
        font-size: 0.1647rem;
        color: #fff;
        line-height: 0.3059rem;

        .name {
          min-width: 1.1765rem;
          max-width: 1.1765rem;
          display: inline-block;
          overflow: hidden;
        }
      }

      .progress-item-value {
        position: absolute;
        top: 0;
        right: .1412rem;
        font-size: 0.1647rem;
        color: #fff;
        line-height: 0.3059rem;
      }
    }
  }
}
</style>
